import React from 'react';
import MapComponent from './MapComponent';
import 'bootstrap/dist/css/bootstrap.css';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Home() {
  return (
    <div id="maincontainer" style={{width:'100%', height:'90vh'}}>
      <Header/>
      <MapComponent />
      <Footer/>
    </div>
  );
}

export default Home;
