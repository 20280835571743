import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function MFooter() {
  return (
    <footer className="footer py-2 footcustom">
      <div className="container text-center">
        <a className="text-light" href="https://www.gcda.kerala.gov.in">&copy; 2024 ജി സി ഡി എ </a>
        <br/>
        <a className="text-light" href="https://zenerom.com/">രൂപകൽപ്പന ചെയ്ത് വികസിപ്പിച്ചത്: സെനെറോം ക്രിയേറ്റിവ് ലാബ്സ് </a>
      </div>
    </footer>
  );
}

export default MFooter;
