import React from 'react';
import Header from '../components/MHeader';
import Footer from '../components/MFooter';

function Projects() {
  return (
    <div>
      <Header/>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="pheadings py-5">പ്രൊജക്റ്റ് ടീം</h1>
          <p class="lead">ഞങ്ങളുടെ ടീം അംഗങ്ങളെ കണ്ടുമുട്ടുക</p>
          <div class="row p-5">
          <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./gcdasec.jpeg" class="card-img" alt="Researcher"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><strong>ശ്രീമതി. ഇന്ദു വിജയനാഥ് </strong></h5>
                    <h6 class="card-subtitle mb-2">സെക്രട്ടറി, ജി.സി.ഡി.എ</h6>
                  </div>
                </div>
              </div>
            </div>              
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="./sheeba.jpg" class="card-img" alt="STP"/>
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title"><strong>ശ്രീമതി. ഷീബ എം.എം</strong></h5>
                  <h6 class="card-subtitle mb-2">സീനിയർ ടൗൺ പ്ലാനർ (i/c), ജി.സി.ഡി.എ</h6>
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-4 col-sm-8">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./sreekanth.jpg" class="card-img" alt="TP"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><strong>ശ്രീ. ശ്രീകാന്ത് ഒ</strong></h5>
                    <h6 class="card-subtitle mb-2">ടൗൺ പ്ലാനിംഗ് ഓഫീസർ, ജി.സി.ഡി.എ</h6>
                  </div>
                </div>
              </div>
            </div>                       
          </div>
          <div class="row p-5">
          <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./manju.jpg" class="card-img" alt="DTP"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><strong>ശ്രീമതി. മഞ്ജു ജവഹർ</strong></h5>
                    <h6 class="card-subtitle mb-2">ഡെപ്യൂട്ടി ടൗൺ പ്ലാനർ, ജി.സി.ഡി.എ</h6>
                  </div>
                </div>
              </div>
            </div>             
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./givin.jpg" class="card-img" alt="Surveyor"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><strong>ശ്രീ. ഗിവിൻ പി.ജി</strong></h5>
                    <h6 class="card-subtitle mb-2">സർവേയർ, ജി.സി.ഡി.എ</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./syam.jpg" class="card-img" alt="Surveyor"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title"><strong>ശ്രീ. ശ്യാം പ്രകാശ്</strong></h5>
                    <h6 class="card-subtitle mb-2">സർവേയർ, ജി.സി.ഡി.എ</h6>
                  </div>
                </div>
              </div>
            </div>                               
          </div>
        </div>
      </div>            
              <Footer/>      
    </div>
  );
}

export default Projects;
