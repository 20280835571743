import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // import Routes
import Header from './components/Header';
import Footer from './components/Footer';
import VisitorCount from './components/VisitorCount';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import MHome from './pages/MHome';
import MAbout from './pages/MAbout';
import MProject from './pages/MProject';
import Disclaimer from './pages/Disclaimer';
import MDisclaimer from './pages/MDisclaimer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.bundle'
import './App.css'

function App() {
  // Define a function to determine whether to show the default header/footer or custom ones
  const showCustomHeaderFooter = () => {
    // Get the current route path
    const currentPath = window.location.pathname;
    // Define an array of paths where you want to show custom header/footer
    const customPaths = ['/MAbout', '/MDisclaimer', '/MHome']; // Add paths for pages with custom header/footer
    // Check if the current path is in the customPaths array
    return customPaths.includes(currentPath);
  };

  // Render the appropriate header based on the current route
  const renderHeader = () => {
    if (showCustomHeaderFooter()) {
      return null;
    } else {
      return <Header />;
    }
  };

  // Render the appropriate footer based on the current route
  const renderFooter = () => {
    if (showCustomHeaderFooter()) {
      return null;
    } else {
      return <Footer />;
    }
  };

  return (
    <Router>
      <div>
        {/* Render the appropriate header */}
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/" element={<Home />} />
          <Route path="/MHome" element={<MHome />} />
          <Route path="/MAbout" element={<MAbout />} />
          <Route path="/MProject" element={<MProject />} />
          <Route path="/MDisclaimer" element={<MDisclaimer />} />
        </Routes>
        {/* Render the appropriate footer */}
      </div>
    </Router>
  );
}

export default App;
