import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function About() {
  return (
    <div>
      <Header/>
      <div className='row'>
      <div className='col-lg-8 col-sm-12'>
      <div class=" col-12 container fs-lg-5 fs-sm-6 fw-bold lh-lg text-justify p-5">
    <h1 class="p-5 text-center pageheadings">Heritage Mapping</h1>
    <p class="readtext">
    In the 14th century, Kochi was formed as a port and a world-renowned market on Fort Kochi- Mattanchery area. Kochi has heritage signs of Arabs, Chinese and Jews coming to trade. Fort Kochi was directly ruled by the Portuguese, then the Dutch, and then the British. So there are vestiges of European colonialism from the 16th century onwards. At the same time, individuals, families and communities who came from various parts of India for shelter, trade and work over generations have given Fort Kochi-Mattanchery a rich cultural diversity.
    </p>
    <p class="readtext">
    Our effort is to infuse this knowledge into this website like a universe in a drop and make it available for everyone in the world to use.
    </p>
    <p class="readtext">
    We have provided around 200 heritage sites in this website dedicated to cultural heritage sites aim to promote and educate people about the importance of these sites. This website generally provide GIS based details, Geo-coordinates, Videos, Photos, Local Body - Village Informations and reports about various cultural heritage sites located in Fort Kochi and Mattanchery. Highlighting the unique architectural features, artistic expressions, and historical events associated with each site. Through these website, visitors can virtually explore these cultural heritage sites, learn about their significance, and understand the cultural context of the heritage sites in Kochi area.
    </p>
    <p class="readtext">
    The main objective of the project is to create a GIS database of heritage sites in the Fort Kochi, Mattanchery region and develop strategies to conserve and promote them. This will further enable the heritage sites to be included in the tourism circuit of the region and also open up the possibilities for future research. This is an attempt to document the cultural and heritage traces left by history in the lives of people in the Fort Kochi Mattancherry area.
    </p>
    <p class="readtext">
    Welcome to all who loves kochi and its heritage glory...
    </p>
    <h1 class="p-5 text-center pageheadings">GCDA An Outlook</h1>
    <p class="readtext">
      The Greater Cochin Development Authority came into existence on 24.01.1976 vide G.O.(MS) No. 19/76/LA&SWD dated 23.01.1976. This is a statutory body corporate formed under the provisions of Travancore Town Planning Act, 1108. It functions under the Local Self Government Department of Government of Kerala.
    </p>
    <p class="readtext">
      As a first step towards the planned Development of the Cochin region an advisory committee was set up in 1965. In the same year, a Joint Town Planning Committee was constituted for Planning and development and this committee was elevated to a trust viz. Cochin Town Planning Trust under the chairmanship of the District Collector. In the course of time, the authorities were convinced of the fact that a larger setup is required to control the growth of Kochi city and its environs in a planned manner which resulted in the formation of GCDA in the year 1976.
    </p>
    <p class="readtext">
      The Authority is a body corporate under the Kerala Town and Country Planning act of 2016 (act 9 of 2016) and notified as per clause 51 of the ibid act by Government of Kerala vide G.O (P) no.47/2016/LSGD dt. 16/12/2016. Besides managing the planned physical development process on its own GCDA has the dual role of coordinating various development agencies operating in this area into a unified goal.
    </p>
    <p class="readtext">
      The jurisdiction of GCDA covered an area of 732 sq. kms consisting of Kochi Corporation, 6 Municipalities and 33 Panchayaths. Later in the year 1994 Goshree Island Development Authority (GIDA) was constituted comprising 8 Island Panchayaths and towards of Cochin Corporation Viz; Thanthonnithuruthu& Fort Vypin having an area of 100sq.kms and thus delimiting the jurisdictional area of GCDA to 632 km<sup>2</sup>.
    </p>
    <p class="readtext"><strong>Corporations:</strong> Kochi except Fort Vypeen and Gundu Island, Thanthonnithuruthu divisions.</p>
    <p class="readtext"><strong>Municipalities:</strong> Aluva, North Paravur, Angamali, Perumbavoor, Tripunithura, Kalamassery, Maradu, Thrikkakkara, and Eloor.</p>
    <p class="readtext"><strong>Panchyaths:</strong> Chellanam, Kumbalanghi, Mulanthuruthy, Cheranelloor, Kumbalam, Udayamperoor, Vadavucode-Puthencruz, Vazhakulam, Choornikara, Edathala, Keezhmad, Chengamanad, Sreemoolanagram, Alangad, Kadungalloor, Ezhikkara, Kottuvally, Nedumbassery, Kanjoor, Varapuzha, and Chottanikkara.</p>
    <ul class="readtext">
      <li>Preparation and implementation of land re-adjustment or land pooling or land banking scheme for the purpose of implementation of projects in the Development Authority area, in tune with the provisions of this act;</li>
      <li>Promoting planned development as envisaged in the plans for the development authority area, through tools like transfer of development rights, accommodation reservation etc.</li>
      <li>Set-up special function agencies, if required, and guide, direct and assist them on matters pertaining to their respective functions</li>
      <li>Co-ordination of implementation of plans under this act in the authority area</li>
      <li>Perform such other functions as are supplemental, incidental or consequential to items (i) to (iii) above or as may be directed by the government, the district planning committee or the metropolitan planning committee, as the case may be, from time to time.</li>
    </ul>    
  </div>
</div>
<div className='col-lg-4 col-sm-12 container d-flex justify-content-center p-5'>
<div className='col-10 fs-lg-5 fs-sm-6  popupmaintexts'>
<div className='container' style={{padding: '20px'}}>
<div class="card" >
  <div class="row no-gutters">
    <div class="col-md-4">
      <img src="./kcp.jpg" class="card-img" alt="chairman"/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title"><strong>Sri. K.Chandran Pillai</strong></h5>
        <h6 class="card-subtitle mb-2 text-muted">GCDA Chairman</h6>
      </div>
    </div>
  </div>
</div>
</div>
  <div className='container' style={{padding: '20px'}}>
  <div class="card" >
    <div class="row no-gutters">
      <div class="col-md-4">
        <img src="./mayor.jpg" class="card-img" alt="mayor"/>
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title"><strong>Sri. M.Anil Kumar</strong></h5>
          <h6 class="card-subtitle mb-2 text-muted">Mayor, Kochi</h6>
        </div>
      </div>
    </div>
  </div>
  </div>
  <div className='container' style={{padding: '20px'}}>
  <div class="card" >
    <div class="row no-gutters">
      <div class="col-md-4">
        <img src="./bony.jpg" class="card-img" alt="projecthead"/>
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h5 class="card-title"><strong>Sri. Bony Thomas</strong></h5>
          <h6 class="card-subtitle mb-2 text-muted">Principal Investigator</h6>
        </div>
      </div>
    </div>
  </div>
  </div>  
</div>
  </div>
</div>
<Footer/>
    </div>
  );
}

export default About;
