import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
  const location = useLocation();

  return (
    <header>
      <nav className="navbar navbar-dark navbar-expand-lg">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="gcdanobg.png" alt="GCDA Logo" className='pr-3' width="50" height="50" style={{ alignSelf: 'flex-start' }} />
            <span style={{ paddingLeft: '10px' }}>GCDA Heritage Mapping</span>
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Heritage Map</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`} to="/about">About</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/projects' ? 'active' : ''}`} to="/projects">Project Team</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.gcda.kerala.gov.in" target="_blank" rel="noopener noreferrer">GCDA Official Website</a>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/disclaimer' ? 'active' : ''}`} to="/disclaimer">Disclaimer</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/MHome' ? 'active' : ''}`} to="/MHome">Malayalam</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;

