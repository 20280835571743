import React from 'react';
import MMapComponent from './MMapComponent';
import MHeader from '../components/MHeader';
import MFooter from '../components/MFooter';
import 'bootstrap/dist/css/bootstrap.css';
import './MHome.css';

function MHome() {
  return (
    <div style={{width:'100%', height:'90vh'}}>
      <MHeader/>
      <MMapComponent />
      <MFooter/>
    </div>
  );
}

export default MHome;
