import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Projects() {
  return (
    <div>
      <Header/>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="pageheadings py-5">Project Team</h1>
          <p class="lead abouttexts">Meet our team members</p>
          <div class="row p-5">
          <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./gcdasec.jpeg" class="card-img" alt="Secretary"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Smt. Indu Vijayanath</h5>
                    <h6 class="card-subtitle mb-2">Secretary, GCDA</h6>
                  </div>
                </div>
              </div>
            </div>              
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
              <div class="col-md-4">
                <img src="./sheeba.jpg" class="card-img" alt="STP"/>
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">Smt. Sheeba M.M</h5>
                  <h6 class="card-subtitle mb-2">Senior Town Planner (i/c), GCDA</h6>
                </div>
              </div>
            </div>
            </div>
            <div class="col-md-4 col-sm-8">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./sreekanth.jpg" class="card-img" alt="TP"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Sri. Sreekanth O</h5>
                    <h6 class="card-subtitle mb-2">Town Planning Officer, GCDA</h6>
                  </div>
                </div>
              </div>
            </div>                       
          </div>
          <div class="row p-5">
          <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./manju.jpg" class="card-img" alt="DTP"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Smt. Manju Javahar</h5>
                    <h6 class="card-subtitle mb-2">Deputy Town Planner, GCDA</h6>
                  </div>
                </div>
              </div>
            </div>             
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./givin.jpg" class="card-img" alt="Surveyor"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Sri. Givin P.G</h5>
                    <h6 class="card-subtitle mb-2">Surveyor, GCDA</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-8">
            <div class="row no-gutters">
                <div class="col-md-4">
                  <img src="./syam.jpg" class="card-img" alt="Surveyor"/>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">Sri. Syam Prakash</h5>
                    <h6 class="card-subtitle mb-2">Surveyor, GCDA</h6>
                  </div>
                </div>
              </div>
            </div>                              
          </div>
        </div>
      </div>                 
              <Footer/>      
    </div>
  );
}

export default Projects;
