import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMapEvents, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import 'leaflet-sidebar-v2/css/leaflet-sidebar.min.css';
import 'leaflet-sidebar-v2';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Autosuggest from 'react-autosuggest';
import shp from 'shpjs';
import { Buffer } from 'buffer';
import './MapComponent.css';

const MapComponent = () => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [boundaryGeoJson, setBoundaryGeoJson] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [selectedFeature, setSelectedFeature] = useState(null);

  const mapRef = useRef();
  const sidebarRef = useRef();

  useEffect(() => {
    // Fetch the GeoJSON data from the file
    axios.get('./output.geojson')
      .then(response => {
        setGeoJsonData(response.data);
      })
      .catch(error => {
        console.error('Error fetching GeoJSON data:', error);
      });

      axios.get('./GCDA.zip', { responseType: 'arraybuffer' })
      .then(response => {
        return shp(response.data);
      })
      .then(geojson => {
        setBoundaryGeoJson(geojson);
      })
      .catch(error => {
        console.error('Error fetching or converting boundary ZIP file:', error);
      });   
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    // Initialize the sidebar
    const sidebar = L.control.sidebar({
      autopan: true,
      container: 'sidebar',
      closeButton: true,
      position: 'left'
    }).addTo(mapRef.current);

    sidebarRef.current = sidebar;
  }, [geoJsonData]);

  useEffect(() => {
    // Dynamically load Font Awesome CSS
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css';
    document.head.appendChild(link);
  }, []);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength === 0 || !geoJsonData) {
      return [];
    }

    return geoJsonData.features.filter(feature =>
      feature.properties.Name.toLowerCase().includes(inputValue)
    );
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.properties.Name;

  const renderSuggestion = (suggestion) => (
    <div>{suggestion.properties.Name}</div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    setSelectedFeature(suggestion);
    const map = mapRef.current;
    if (map) {
      const latlng = [
        suggestion.geometry.coordinates[1],
        suggestion.geometry.coordinates[0]
      ];
      map.flyTo(latlng, 14); // Zoom in on selected feature

      // Create a popup and open it at the selected feature's location
      const { Name, Name_of_District, Name_of_Local_Body, Name_of_Village, Nameof_ward, PdfUrl, PageNumber, ImgUrl, VideoTime } = suggestion.properties;
      const popupContent = `
            <div>
              <h5 class='popupheader text-center'>${Name}</h5>
              <hr/>
              <iframe class='popytb' width="100%" height="150" src="https://www.youtube.com/embed/EfAU6X-XlU0?si=1WCidF6CJE8oG0kC&amp;start=${VideoTime}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div>
              <div class='col-12 image-container popytb' style="background-image: url('${ImgUrl}'); background-size: cover; background-position: center;"></div>
            </div>
            <hr/>
            <div class='row'>
              <div class='col-12'>
                <table class="popup-table">
                  <tbody>
                    <tr>
                      <td class='popupmaintexts'><strong>District:</strong></td>
                      <td class='popupmaintexts'>${Name_of_District}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Local Body:</strong></td>
                      <td class='popupmaintexts' >${Name_of_Local_Body}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Village:</strong></td>
                      <td class='popupmaintexts'>${Name_of_Village}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Ward:</strong></td>
                      <td class='popupmaintexts'>${Nameof_ward}</td>
                    </tr>
                  </tbody>
                </table> 
              </div>
            </div>
            <div class="row">           
              <div class="col-6 float-left">
                <button onclick="openPdfPopup('${PdfUrl}', ${PageNumber})" class="btn btn-success btn-sm btntexts">
                  Details
                </button>
              </div>
              <div class="col-6 float-right">
                <button
                  class="btn btn-success btn-sm btntexts"
                  onClick="window.open('https://www.google.com/maps?q=' + ${suggestion.geometry.coordinates[1]} + ',' + ${suggestion.geometry.coordinates[0]}, '_blank');">
                  Google Maps
                </button>
              </div>
            </div> 
      `;
      map.once('moveend', () => {
        // Calculate a slight offset to center the popup
        const offset = map.getSize().y * 0.1;
        const newLatLng = map.containerPointToLatLng(
          map.latLngToContainerPoint(latlng).subtract([0, offset])
        );
        map.setView(newLatLng, map.getZoom(), { animate: false });
      L.popup()
        .setLatLng(latlng)
        .setContent(popupContent)
        .openOn(map);
      });
    }
  };

  const inputProps = {
    placeholder: "Search for a location...",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    }
  };

  // Define the custom icon with the path to your marker icon
  const customIcon = new L.Icon({
    iconUrl: icon, // Ensure this path points to your custom marker icon
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [41, 41]
  });

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const { Name, Name_of_District, Name_of_Local_Body, Name_of_Village, Nameof_ward, PdfUrl, PageNumber, ImgUrl, VideoTime  } = feature.properties;
      const popupContent = `
            <div>
              <h5 class='popupheader text-center'>${Name}</h5>
              <hr/>
              <iframe class='popytb' width="100%" height="150" src="https://www.youtube.com/embed/EfAU6X-XlU0?si=1WCidF6CJE8oG0kC&amp;start=${VideoTime}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div>
              <div class='col-12 image-container popytb' style="background-image: url('${ImgUrl}'); background-size: cover; background-position: center;"></div>
            </div>
            <hr/>
            <div class='row'>
              <div class='col-12'>
                <table class="popup-table">
                  <tbody>
                    <tr>
                      <td class='popupmaintexts'><strong>District:</strong></td>
                      <td class='popupmaintexts'>${Name_of_District}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Local Body:</strong></td>
                      <td class='popupmaintexts' >${Name_of_Local_Body}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Village:</strong></td>
                      <td class='popupmaintexts'>${Name_of_Village}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Ward:</strong></td>
                      <td class='popupmaintexts'>${Nameof_ward}</td>
                    </tr>
                  </tbody>
                </table> 
              </div>
            </div>
            <div class="row">           
              <div class="col-6 float-left">
                <button onclick="openPdfPopup('${PdfUrl}', ${PageNumber})" class="btn btn-success btn-sm btntexts">
                  Details
                </button>
              </div>
              <div class="col-6 float-right">
                <button
                  class="btn btn-success btn-sm btntexts"
                  onClick="window.open('https://www.google.com/maps?q=' + ${feature.geometry.coordinates[1]} + ',' + ${feature.geometry.coordinates[0]}, '_blank');">
                  Google Maps
                </button>
              </div>
            </div> 
      `;
      const tooltipContent = `
        <div>
          <strong>${Name}</strong>
        </div>
      `;
      layer.bindPopup(popupContent);
      layer.bindTooltip(tooltipContent, { sticky: true });
    }
  };

  const pointToLayer = (feature, latlng) => {
    return L.marker(latlng, { icon: customIcon });
  };

  const handleSidebarItemClick = (feature) => {
    const map = mapRef.current;
    const latlng = [
      feature.geometry.coordinates[1],
      feature.geometry.coordinates[0]
    ];
    map.flyTo(latlng, 18); // Zoom in on selected feature

    // Create a popup and open it at the selected feature's location
    const { Name, Name_of_District, Name_of_Local_Body, Name_of_Village, Nameof_ward, PdfUrl, PageNumber, ImgUrl, VideoTime } = feature.properties;
    const popupContent = `
            <div>
              <h5 class='popupheader text-center'>${Name}</h5>
              <hr/>
              <iframe class='popytb' width="100%" height="150" src="https://www.youtube.com/embed/EfAU6X-XlU0?si=1WCidF6CJE8oG0kC&amp;start=${VideoTime}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <div>
              <div class='col-12 image-container popytb' style="background-image: url('${ImgUrl}'); background-size: cover; background-position: center;"></div>
            </div>
            <hr/>
            <div class='row'>
              <div class='col-12'>
                <table class="popup-table">
                  <tbody>
                    <tr>
                      <td class='popupmaintexts'><strong>District:</strong></td>
                      <td class='popupmaintexts'>${Name_of_District}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Local Body:</strong></td>
                      <td class='popupmaintexts' >${Name_of_Local_Body}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Village:</strong></td>
                      <td class='popupmaintexts'>${Name_of_Village}</td>
                    </tr>
                    <tr>
                      <td class='popupmaintexts'><strong>Ward:</strong></td>
                      <td class='popupmaintexts'>${Nameof_ward}</td>
                    </tr>
                  </tbody>
                </table> 
              </div>
            </div>
            <div class="row">           
              <div class="col-6 float-left">
                <button onclick="openPdfPopup('${PdfUrl}', ${PageNumber})" class="btn btn-success btn-sm btntexts">
                  Details
                </button>
              </div>
              <div class="col-6 float-right">
                <button
                  class="btn btn-success btn-sm btntexts"
                  onClick="window.open('https://www.google.com/maps?q=' + ${feature.geometry.coordinates[1]} + ',' + ${feature.geometry.coordinates[0]}, '_blank');">
                  Google Maps
                </button>
              </div>
            </div> 
    `;
    L.popup()
      .setLatLng(latlng)
      .setContent(popupContent)
      .openOn(map);
  };

        // Function to open PDF in a popup window
        window.openPdfPopup = async (pdfUrl, pageNumber) => {
          console.log(pdfUrl, pageNumber);
          const popupWidth = 600;
          const popupHeight = 400;
          const popupFeatures = `width=${popupWidth},height=${popupHeight},scrollbars=yes,resizable=yes,menubar=no,toolbar=no,location=no,status=no`;
        
          // Open a blank popup window
          const popupWindow = window.open('', '_blank', popupFeatures);
        
          // Function to create an iframe with the PDF
          const createIframe = (urlWithPageNumber) => {
            const iframeElement = document.createElement('iframe');
            iframeElement.src = urlWithPageNumber;
            iframeElement.width = '100%';
            iframeElement.height = '100%';
            iframeElement.style.border = 'none'; // Remove border
        
            // Append the iframe to the popup window's document body
            popupWindow.document.body.appendChild(iframeElement);
          };
        
          try {
            // Fetch the PDF with cache settings
            const response = await fetch(pdfUrl, {
              method: 'GET',
              headers: {
                'Cache-Control': 'public, max-age=31536000' // 1 year cache duration
              },
              cache: 'force-cache'
            });
        
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
        
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            const urlWithPageNumber = `${objectUrl}#page=${pageNumber}`;
            createIframe(urlWithPageNumber);
        
            // Optionally, revoke the object URL after some time to free up memory
            setTimeout(() => URL.revokeObjectURL(objectUrl), 60000); // 1 minute
          } catch (error) {
            console.error('Error downloading PDF:', error);
            popupWindow.document.body.innerHTML = '<p>Failed to load PDF. Please try again later.</p>';
          }
        };

  return (
    <div className="map-container">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        theme={{
          input: 'autosuggest-input',
          suggestionsContainer: 'autosuggest-suggestions-container'
        }}
      />
      <div id="sidebar" className="leaflet-sidebar collapsed">
        <div className="leaflet-sidebar-tabs">
          <ul role="tablist">
            <li><a href="#list" role="tab"><i className="fa fa-list"></i></a></li>
          </ul>
          <ul role="tablist">
            <li><a href="#" role="tab"><i className="fa fa-close"></i></a></li>
          </ul>
        </div>
        <div className="leaflet-sidebar-content">
          <div className="leaflet-sidebar-pane" id="list">
            <h1 className="leaflet-sidebar-header">Fort Cochin & Mattancherry<span className="leaflet-sidebar-close"><i className="fa fa-caret-left"></i></span></h1>
            <div>
              {geoJsonData && geoJsonData.features.map((feature, index) => (
                <div
                  key={index}
                  className="sidebar-item"
                  onClick={() => handleSidebarItemClick(feature)}
                >
                  {feature.properties.Name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <MapContainer center={[9.965881309546887, 76.2463402748108]} zoomControl={false} zoom={16} style={{ height: "88vh", width: "100%" }} ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {geoJsonData && <GeoJSON data={geoJsonData} onEachFeature={onEachFeature} pointToLayer={pointToLayer} />}
        {boundaryGeoJson && <GeoJSON data={boundaryGeoJson} />}
        {selectedFeature && (
          <GeoJSON
            data={{ type: "FeatureCollection", features: [selectedFeature] }}
            style={{ color: "red" }}
          />
        )}
        <ZoomControl position="bottomright" />
        <MapEventsLogger />
      </MapContainer>
    </div>
  );
};

const MapEventsLogger = () => {
  const map = useMapEvents({
    mousemove: () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      console.log(`Center: Lat: ${center.lat}, Lng: ${center.lng}, Zoom level: ${zoom}`);
    },
    zoomend: () => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      console.log(`Center: Lat: ${center.lat}, Lng: ${center.lng}, Zoom level: ${zoom}`);
    }
  });
  return null;
};

export default MapComponent;
