import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Disclaimer() {
  return (
    <div>
      <Header/>      
      <div className='col-10 container fs-lg-5 fs-sm-6 fw-bold  abouttexts lh-lg text-justify p-5'>
      <h1>Website Disclaimer</h1>
        <p>The information provided by Greater Cochin Development Authority ('we', 'us', or 'our') on <a href="https://gcdaheritagesitemapping.in/">https://gcdaheritagesitemapping.in/</a> (
          the 'Site') and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, 
          however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of 
          any information on the Site or our mobile application.</p>

        <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or our mobile application or 
          reliance on any information provided on the site and our mobile application. 
          Your use of the site and our mobile application and your reliance on any information on the site and our mobile application is solely at your own risk.</p>

          <h1>External Links Disclaimer</h1>

        <p>The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating 
          from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, 
          validity, reliability, availability, or completeness by us.</p>

        <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through 
          the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction 
          between you and third-party providers of products or services.</p>
      </div>
      <Footer/>      
    </div>
  );
}

export default Disclaimer;
