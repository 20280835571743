import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import VisitorCount from './VisitorCount';


function Footer() {
  return (
    <footer className="footer footcustom">
      <div className="container-fluid text-center">
        <a className="text-light" href="https://www.gcda.kerala.gov.in">&copy; 2024 GCDA</a>
        <br/>
        <a className="text-light" href="https://zenerom.com/">Designed & Developed by Zenerom Creative Labs</a>
      </div>
    </footer>
  );
}

export default Footer;
