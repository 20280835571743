import React from 'react';
import Header from '../components/MHeader';
import Footer from '../components/MFooter';

function MDisclaimer() {
  return (
    <div>
      <Header/>
      <div className='container-fluid'>
        <div class="col-10 offset-1">
          <h1 class="p-5 text-center mpheadings">ബാദ്ധ്യതാ നിരാകരണം</h1>
          <h3 class="mpheadings">വെബ്സൈറ്റ് ബാധ്യതാനിരാകരണരേഖ</h3>
          <div class="row">
            <div class="col">
              <div class="text-justify">

          <p class="mabouttexts">
            ഗ്രേറ്റർ കൊച്ചിൻ ഡെവലപ്‌മെൻ്റ് അതോറിറ്റിയും ('ഞങ്ങൾ', 'ഞങ്ങൾ', അല്ലെങ്കിൽ 'ഞങ്ങളുടെ') https://gcdaheritagesitemapping.in/ ('സൈറ്റ്') എന്നതിലും 
            ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷനും നൽകുന്ന വിവരങ്ങൾ പൊതുവായ വിവര ആവശ്യങ്ങൾക്ക് മാത്രമുള്ളതാണ്. സൈറ്റിലെയും 
            ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷനിലെയും എല്ലാ വിവരങ്ങളും നല്ല വിശ്വാസത്തോടെയാണ് നൽകിയിരിക്കുന്നത്, എന്നിരുന്നാലും സൈറ്റിലെ ഏതെങ്കിലും 
            വിവരങ്ങളുടെ കൃത്യത, പര്യാപ്തത, സാധുത, വിശ്വാസ്യത, ലഭ്യത അല്ലെങ്കിൽ പൂർണ്ണത എന്നിവ സംബന്ധിച്ച് ഞങ്ങൾ ഏതെങ്കിലും തരത്തിലുള്ള പ്രതിനിധാനം 
            അല്ലെങ്കിൽ വാറൻ്റി നൽകുന്നില്ല. അല്ലെങ്കിൽ ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷൻ.

            സൈറ്റിൻ്റെയോ ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷൻ്റെയോ ഉപയോഗത്തിൻ്റെയോ സൈറ്റിലും ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷനിലും നൽകിയിരിക്കുന്ന 
            ഏതെങ്കിലും വിവരങ്ങളെ ആശ്രയിക്കുന്നതിൻ്റെ ഫലമായി ഉണ്ടാകുന്ന ഏതെങ്കിലും തരത്തിലുള്ള നഷ്‌ടത്തിനോ നാശത്തിനോ ഒരു സാഹചര്യത്തിലും ഞങ്ങൾക്ക് 
            നിങ്ങളോട് യാതൊരു ബാധ്യതയും ഉണ്ടായിരിക്കുന്നതല്ല. സൈറ്റിൻ്റെയും ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷൻ്റെയും നിങ്ങളുടെ ഉപയോഗവും സൈറ്റിലെയും ഞങ്ങളുടെ 
            മൊബൈൽ ആപ്ലിക്കേഷനിലെയും ഏത് വിവരത്തെയും ആശ്രയിക്കുന്നതും നിങ്ങളുടെ സ്വന്തം ഉത്തരവാദിത്തത്തിൽ മാത്രമാണ്.</p>

            </div>
            </div>

            
            <h3 class="mpheadings">ബാഹ്യ ലിങ്കുകളുടെ ബാധ്യതാനിരാകരണരേഖ</h3>
            
            <p>
            സൈറ്റിലും ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷനിലും മറ്റ് വെബ്‌സൈറ്റുകളിലേക്കോ മൂന്നാം കക്ഷികളിൽ നിന്നുള്ളതോ ഉത്ഭവിച്ചതോ ആയ 
            ഉള്ളടക്കത്തിലേക്കുള്ള ലിങ്കുകൾ അല്ലെങ്കിൽ ബാനറുകളിലോ മറ്റ് പരസ്യങ്ങളിലോ ഉള്ള വെബ്‌സൈറ്റുകളിലേക്കും ഫീച്ചറുകളിലേക്കും ഉള്ള ലിങ്കുകൾ 
            അടങ്ങിയിരിക്കാം (അല്ലെങ്കിൽ നിങ്ങൾക്ക് സൈറ്റ് അല്ലെങ്കിൽ ഞങ്ങളുടെ മൊബൈൽ ആപ്ലിക്കേഷൻ വഴി അയച്ചേക്കാം). അത്തരം ബാഹ്യ ലിങ്കുകൾ ഞങ്ങൾ കൃത്യത, 
            പര്യാപ്തത, സാധുത, വിശ്വാസ്യത, ലഭ്യത അല്ലെങ്കിൽ 
            പൂർണ്ണത എന്നിവയ്ക്കായി അന്വേഷിക്കുകയോ നിരീക്ഷിക്കുകയോ പരിശോധിക്കുകയോ ചെയ്യുന്നില്ല.

            സൈറ്റിലൂടെ ലിങ്ക് ചെയ്‌തിരിക്കുന്ന മൂന്നാം കക്ഷി വെബ്‌സൈറ്റുകൾ അല്ലെങ്കിൽ ഏതെങ്കിലും ബാനറിലോ മറ്റ് പരസ്യങ്ങളിലോ ലിങ്ക് 
            ചെയ്‌തിരിക്കുന്ന ഏതെങ്കിലും വെബ്‌സൈറ്റ് അല്ലെങ്കിൽ ഫീച്ചർ മുഖേന നൽകുന്ന ഏതെങ്കിലും വിവരങ്ങളുടെ കൃത്യതയോ വിശ്വാസ്യതയോ ഞങ്ങൾ 
            വാറൻ്റ് ചെയ്യുകയോ അംഗീകരിക്കുകയോ ഉറപ്പ് നൽകുകയോ ഉത്തരവാദിത്തം ഏറ്റെടുക്കുകയോ ചെയ്യുന്നില്ല. നിങ്ങൾക്കും ഉൽപ്പന്നങ്ങളുടെയോ സേവനങ്ങളുടെയോ 
            മൂന്നാം കക്ഷി ദാതാക്കളും തമ്മിലുള്ള ഏതെങ്കിലും ഇടപാട് നിരീക്ഷിക്കുന്നതിന് ഞങ്ങൾ ഒരു കക്ഷിയോ ഒരു തരത്തിലും ഉത്തരവാദിയോ ആയിരിക്കില്ല.
            </p>
            </div>
          </div>
        </div>      
      <Footer/>      
    </div>
  );
}

export default MDisclaimer;