import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MHeader.css';

function MHeader() {
  const location = useLocation();

  return (
    <header>
      <nav className="navbar navbar-dark navbar-expand-lg mnavbar">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="gcdanobg.png" alt="GCDA Logo" className='pr-3' width="50" height="50" style={{ alignSelf: 'flex-start' }} />
            <span style={{ paddingLeft: '10px' }}>ജി.സി.ഡി.എ പൈതൃക മാപ്പിംഗ്</span>
          </Link>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/MHome' ? 'active' : ''}`} to="/MHome">പൈതൃക മാപ്പിംഗ്</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/MAbout' ? 'active' : ''}`} to="/MAbout">വിവരണം</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/MProject' ? 'active' : ''}`} to="/MProject">പ്രൊജക്റ്റ് ടീം</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.gcda.kerala.gov.in" target="_blank" rel="noopener noreferrer">ജി സി ഡി എ വെബ്സൈറ്റ് </a>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/MDisclaimer' ? 'active' : ''}`} to="/MDisclaimer">ബാദ്ധ്യതാ നിരാകരണം</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">English</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default MHeader;
